(function () {
    document.addEventListener('DOMContentLoaded', () => {
        const nav = document.body.querySelector('.header-menu'),
            width = nav.scrollWidth;
        document.addEventListener('click', (e) => {
            const target = e.target;
            if (target && target.classList.contains('close')){
                document.body.classList.toggle('height-mobil');
                if (nav.hasAttribute('data-open')) {
                    nav.removeAttribute('data-open', 0);
                    nav.style.transition = 'all 0.5s ease 0s';
                    nav.style.transform = "translateX(-".concat(width, "px)");
                } else {
                    nav.setAttribute('data-open', 1);
                    nav.style.transition = 'all 0.5s ease 0s';
                    nav.style.transform = "translateX(0)";
                }
            }
        })
    })
})();
